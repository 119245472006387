export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [0,12,2,7,8,3,6,10];

export const dictionary = {
		"/(public)": [~51,[12]],
		"/(private)/create": [13,[2]],
		"/(private)/create/[apptypeId]": [~14,[2]],
		"/(public)/login": [~52,[12]],
		"/(public)/login/email": [53,[12]],
		"/(public)/login/forgotten-password": [54,[12]],
		"/(public)/login/forgotten-password/password": [55,[12]],
		"/(private)/medusajs_storefront/[appId]": [36,[2,7]],
		"/(private)/medusajs_storefront/[appId]/deployments": [37,[2,7]],
		"/(private)/medusajs_storefront/[appId]/deployments/[deploymentId]": [38,[2,7]],
		"/(private)/medusajs_storefront/[appId]/settings/domains": [39,[2,7,8]],
		"/(private)/medusajs_storefront/[appId]/settings/envs": [40,[2,7,8]],
		"/(private)/medusajs_storefront/[appId]/settings/general": [41,[2,7,8]],
		"/(private)/medusajs_storefront/[appId]/settings/git": [42,[2,7,8]],
		"/(private)/medusajs_storefront/[appId]/settings/logs": [43,[2,7,8]],
		"/(private)/medusajs_storefront/[appId]/settings/plans": [44,[2,7,8]],
		"/(private)/medusajs/questionnaire": [35,[2]],
		"/(private)/medusajs/[appId]": [15,[2,3]],
		"/(private)/medusajs/[appId]/actions/general": [16,[2,3,4]],
		"/(private)/medusajs/[appId]/database/backups": [~17,[2,3,5]],
		"/(private)/medusajs/[appId]/database/info": [18,[2,3,5]],
		"/(private)/medusajs/[appId]/database/migrations": [19,[2,3,5]],
		"/(private)/medusajs/[appId]/database/whitelist": [20,[2,3,5]],
		"/(private)/medusajs/[appId]/deployments": [21,[2,3]],
		"/(private)/medusajs/[appId]/deployments/[deploymentId]": [22,[2,3]],
		"/(private)/medusajs/[appId]/help": [~23,[2,3]],
		"/(private)/medusajs/[appId]/logs": [24,[2,3]],
		"/(private)/medusajs/[appId]/monitoring": [25,[2,3]],
		"/(private)/medusajs/[appId]/plugins": [26,[2,3]],
		"/(private)/medusajs/[appId]/settings/deployments": [27,[2,3,6]],
		"/(private)/medusajs/[appId]/settings/domains": [28,[2,3,6]],
		"/(private)/medusajs/[appId]/settings/envs": [29,[2,3,6]],
		"/(private)/medusajs/[appId]/settings/general": [30,[2,3,6]],
		"/(private)/medusajs/[appId]/settings/git": [31,[2,3,6]],
		"/(private)/medusajs/[appId]/settings/logs": [32,[2,3,6]],
		"/(private)/medusajs/[appId]/settings/plans": [33,[2,3,6]],
		"/(private)/medusajs/[appId]/storefront": [34,[2,3]],
		"/(public)/signup": [56,[12]],
		"/(public)/signup/password": [57,[12]],
		"/(private)/user/information": [45,[2]],
		"/(private)/user/settings/general": [46,[2,9]],
		"/(private)/user/settings/git": [47,[2,9]],
		"/(private)/vps/[vpsId]": [48,[2,10]],
		"/(private)/vps/[vpsId]/actions": [49,[2,10]],
		"/(private)/vps/[vpsId]/settings/general": [50,[2,10,11]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';